

















import Vue from "vue";
import type { PropType } from "vue";
import { Subject, Observable } from "rxjs";
import { SearchIndex } from "algoliasearch";
import SearchEngine, { SearchQuery } from "@/helpers/SearchEngine";

import List from "./List.vue";

export default Vue.extend({
  name: "ListSearch",
  components: {
    List
  },
  props: {
    getSearchClient: { type: Function as PropType<() => Promise<SearchIndex>>, required: true },
    getSearchParameters: {
      type: Function as PropType<() => Promise<SearchQuery>>,
      required: true
    },
    itemName: { type: String, default: "Item" },
    onReset: { type: Observable, default: () => new Subject().asObservable() }
  },
  data() {
    return {
      atEnd: false,
      engine: new SearchEngine(this.getSearchClient, this.getSearchParameters, this.onReset)
    };
  },
  beforeDestroy() {
    this.engine.unsubscribe();
  },
  methods: {
    resetSearch(): void {
      this.atEnd = false;
      this.engine.resetSearch();
    },
    async fetchMoreItems(): Promise<void> {
      this.atEnd = !this.engine.moreResultsToLoad;
      await this.engine.fetchMoreItems();
      this.atEnd = !this.engine.moreResultsToLoad;
    }
  }
});
