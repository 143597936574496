






























import Vue from "vue";
import orderBy from "lodash/orderBy";
import { db } from "@/firebase";
import { Subject } from "rxjs";

import AccountCreateModal from "@/components/AccountCreateModal.vue";
import AccountListItem from "@/components/AccountListItem.vue";
import AccountSearchList from "@/components/AccountSearchList.vue";
import AddButton from "@/components/AddButton.vue";
import ListFirestore from "@/components/ListFirestore.vue";
import SearchInput from "@/components/SearchInput.vue";
import SortByDropdown from "@/components/SortByDropdown.vue";

export default Vue.extend({
  name: "Accounts",
  components: {
    AccountCreateModal,
    AccountListItem,
    AccountSearchList,
    AddButton,
    ListFirestore,
    SearchInput,
    SortByDropdown
  },
  data: () => ({
    createAccountModalIsOpen: false,
    onReset: new Subject(),
    search: "",
    searchIsFocused: false
  }),
  computed: {
    accountSortPreference(): Sort<Account.Info> | null {
      return this.$store.direct.state.ui.sortPreferences.accounts ?? null;
    },
    minItemHeight: () => 73,
    showSortOption(): boolean {
      return !this.search && !this.searchIsFocused;
    },
    sortDirection(): SortDirection {
      return this.accountSortPreference?.direction ?? "asc";
    },
    sortOptions: () => ({
      createdAt: "Date Created",
      name: "Name"
    }),
    sortType(): keyof Account.Info {
      return this.accountSortPreference?.fieldPath ?? "createdAt";
    }
  },
  watch: {
    sortDirection() {
      this.onReset.next(null);
    },
    sortType() {
      this.onReset.next(null);
    }
  },
  methods: {
    closeModal(): void {
      this.createAccountModalIsOpen = false;
    },
    getAccountsCollection(): FirestoreQuery {
      return db.collection("accounts").orderBy(this.sortType, this.sortDirection);
    },
    onAddedOrUpdatedItems(accountsToAdd: Dictionary<AccountStoreEntry>): void {
      this.$store.direct.commit.ADD_OR_UPDATE_SEVERAL_ACCOUNTS(accountsToAdd);
    },
    openModal(): void {
      this.createAccountModalIsOpen = true;
    },
    orderList<T>(items: Record<string, T>): T[] {
      return orderBy(Object.values(items), this.sortType, this.sortDirection);
    },
    searchFocused(): void {
      this.searchIsFocused = true;
    },
    searchLostFocus(): void {
      this.searchIsFocused = false;
    },
    selectSortOption(fieldPath: keyof Account.Info): void {
      let direction: SortDirection = "asc";
      switch (fieldPath) {
        case "createdAt":
          direction = "desc";
          break;
        case "name":
        default:
          direction = "asc";
          break;
      }
      return this.$store.direct.commit.UPDATE_SORT_PREFERENCE({
        domain: "accounts",
        preference: { fieldPath, direction }
      });
    }
  }
});
