







import Vue, { PropType } from "vue";
import ActionButton from "./ActionButton.vue";
import PlusIcon from "../icons/Plus.vue";

export default Vue.extend({
  name: "AddButton",
  components: {
    ActionButton,
    PlusIcon
  },
  props: {
    isLoading: { type: Boolean, default: false },
    type: { type: String, default: "button" },
    kind: { type: String, default: "secondary" },
    to: { type: String, default: "" },
    href: { type: String, default: "" },
    download: { type: Boolean, default: false },
    extraClasses: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    title: { type: String as PropType<string | null>, default: null },
    tooltip: { type: String as PropType<string | null>, default: null }
  },
  methods: {
    onClick(event: Event): void {
      this.$emit("click", event);
    }
  }
});
