















import Vue from "vue";

import DropdownIcon from "@/icons/Dropdown.vue";

export default Vue.extend({
  name: "SortByDropdown",
  components: {
    DropdownIcon
  },
  props: {
    value: { type: String, required: true },
    options: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      selectValue: this.value
    };
  },
  watch: {
    value(newValue: string) {
      this.selectValue = newValue;
    }
  },
  methods: {
    focus(): void {
      const select = this.$refs.select as HTMLSelectElement | undefined;
      select?.click();
    },
    onChange(event: Event): void {
      const eventTarget = event.target as HTMLSelectElement | null;
      this.$emit("input", eventTarget?.value);
    }
  }
});
