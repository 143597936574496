







import Vue, { PropType } from "vue";
import { callableFunctions } from "@/firebase";

import Modal from "./Modal.vue";
import AccountForm from "./AccountForm.vue";

export default Vue.extend({
  name: "AccountCreateModal",
  components: {
    AccountForm,
    Modal
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function as PropType<() => void>, required: true }
  },
  computed: {
    currentUserId(): string | null {
      return this.$store.direct.state.currentUser.uid;
    }
  },
  methods: {
    async createAccount({
      name,
      website,
      phoneNumber,
      customerEmail
    }: {
      name: string;
      website: string;
      phoneNumber: string;
      customerEmail: string;
    }): Promise<void> {
      console.log("addAccount", { name, website, phoneNumber, customerEmail });
      const result = await callableFunctions.registerAccount({
        name,
        website,
        phoneNumber,
        customerEmail
      });
      const data = result.data as { newAccountId: string };
      if ("newAccountId" in data && typeof data.newAccountId === "string") {
        void this.$router.push(`/app/accounts/${data.newAccountId}`);
        this.closeModal();
      } else {
        console.error("Unexpected result:", data);
        throw new Error(
          "Got an unexpected result from the server. Contact Referral Reactor for assistance."
        );
      }
    }
  }
});
