













import Vue from "vue";
import { Subject } from "rxjs";
import { SearchIndex } from "algoliasearch/lite";
import { SearchQuery } from "@/helpers/SearchEngine";
import { Timestamp } from "@/firebase";

import AccountListItem from "@/components/AccountListItem.vue";
import ListSearch from "./ListSearch.vue";

export default Vue.extend({
  name: "AccountSearchList",
  components: {
    AccountListItem,
    ListSearch
  },
  provide() {
    return {
      reloadList: this.onReset
    };
  },
  props: {
    search: { type: String, default: "" },
    selectedSortOption: { type: String, default: "id" }
  },
  data: () => ({
    onReset: new Subject(),
    sortOption: ""
  }),
  watch: {
    search(): void {
      this.onReset.next(null);
    },
    selectedSortOption(selectedSortOption: string) {
      this.onReset.next(null);
      this.sortOption = selectedSortOption;
    }
  },
  mounted() {
    this.sortOption = this.selectedSortOption;
  },
  methods: {
    async getSearchClient(): Promise<SearchIndex> {
      return await this.$store.direct.dispatch.getAccountsSearchIndex();
    },
    getSearchParameters(): SearchQuery {
      return { query: this.search };
    },
    processSearchResult(account: AccountSearchResult): Partial<Account.Info> {
      return {
        ...account,
        id: account.objectID,
        createdAt: new Timestamp(new Date(account.createdAt).getTime() / 1000, 0)
      };
    }
  }
});
