












import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "AccountListItem",
  components: {},
  props: {
    account: { type: Object as PropType<Account.Info>, required: true },
    selected: { type: Boolean, default: false }
  }
});
