import { Subject, Observable, Subscription } from "rxjs";
import { SearchIndex } from "algoliasearch";
import type { MultipleQueriesOptions } from "@algolia/client-search";

export type SearchParams = MultipleQueriesOptions & Record<string, unknown>;

export interface SearchQuery {
  query: string;
  config?: SearchParams;
}

/**
 * An object that manages access to Algolia's search indices.
 */
export default class SearchEngine<T> {
  /**
   * Create a SearchEngine.
   * @param getSearch Called to obtain information about the search query.
   * @param getSearchClient Called to obtain information about the search client.
   * @param onReset Called when the search needs to be reset.
   */
  constructor(
    public getSearchClient: () => Promise<SearchIndex>,
    public getSearch: () => Promise<SearchQuery>,
    public onReset: Observable<T> = new Subject<T>().asObservable()
  ) {
    this.client = null;
    this.getSearch = getSearch;
    this.getSearchClient = getSearchClient;
    this.mostRecentSearchParams = null;
    this.onReset = onReset;

    this.error = null;
    this.items = [];
    this.loading = false;
    this.moreResultsToLoad = true;
    this.page = 0;
    this.searchIndex = null;

    this.resetSubscription = this.onReset.subscribe(() => {
      this.resetSearch();
    });
  }

  client: SearchIndex | null;
  error: Error | null;
  items: T[];
  loading: boolean;
  mostRecentSearchParams: SearchParams | null;
  moreResultsToLoad: boolean;
  page: number;
  resetSubscription: Subscription | null;
  searchIndex: SearchIndex | null;

  unsubscribe(): void {
    this.resetSubscription?.unsubscribe();
    this.resetSubscription = null;
  }

  resetSearch(): void {
    this.moreResultsToLoad = true;
    this.page = 0;
    this.error = null;
    void this.fetchMoreItems();
  }

  async fetchMoreItems(): Promise<void> {
    if (this.moreResultsToLoad) {
      this.loading = true;
      const { query, config } = await this.getSearch();
      const searchParameters: SearchParams = {
        page: this.page,
        hitsPerPage: 20,
        responseFields: "*",
        attributesToRetrieve: "*",
        ...config
      };
      this.mostRecentSearchParams = searchParameters;
      try {
        if (!this.client) {
          this.client = await this.getSearchClient();
        }
        const searchResponse = await this.client.search<T>(query, searchParameters);
        if (searchParameters === this.mostRecentSearchParams) {
          if (searchResponse.hits.length === 0) {
            this.moreResultsToLoad = false;
          } else {
            if (searchResponse.hits.length < 20) {
              this.moreResultsToLoad = false;
            }
            console.log("SearchEngine", searchResponse.hits.length, this.moreResultsToLoad);
            const searchResults = searchResponse.hits;
            if (this.page === 0) {
              this.items = searchResults;
            } else {
              this.items = this.items.concat(searchResults);
            }
            this.page += 1;
          }
        }
      } catch (error) {
        console.error(error);
        this.error = error as Error;
      }
      this.loading = false;
    }
  }
}
